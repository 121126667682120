<template lang="pug">
  #data-container
    div#div-container
      h1.fast &lt;TOTAL SALES&gt;
    h1(:title="fullPrettySum") 💰 {{fullPrettySum}}! 💰
    h2 {{goalPercent}}% OF OUR {{goalAmount}} GOAL
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { GOALS } from '../../utils/constants'
import { moneyFormat } from '../../utils/functions'

export default {
  name: 'sales-data',
  computed: {
    ...mapState(['sum']),
    ...mapGetters(['prettySum', 'goalPercent']),
    goalAmount() {
      return moneyFormat(GOALS.jet)
    },
    fullPrettySum() {
      return moneyFormat(this.sum)
    }
  }
}
</script>

<style lang="scss" scoped>
#data-container {
  margin: 1rem 0;
  color: #414042;
}
#div-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fast {
  font-family: 'Fast Forward', monospace;
  font-size: 3rem;
  color: #c8102e;
  margin-bottom: 1rem;
  position: relative;
}
</style>
