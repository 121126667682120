<template lang="pug">
  #spinner
    h1 Loading data...
    semipolar-spinner(:animation-duration="1500" :size="400" color="#c8102e")
</template>

<script>
import { SemipolarSpinner } from 'epic-spinners'

export default {
  name: 'loading-spinner',
  components: {
    SemipolarSpinner
  }
}
</script>

<style lang="scss" scoped>
#spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  color: #c8102e;
}
</style>
