<template lang="pug">
  #marker(:style="styleObject") 
    span {{finalText}}
</template>

<script>
import { mapState } from 'vuex'
import { GOALS } from '../../utils/constants'
export default {
  name: 'goal-marker',
  props: {
    goalPosition: Number,
    goalText: String,
    goalType: String
  },
  data() {
    return {
      styleObject: {
        top: `${this.goalPosition}%`
      }
    }
  },
  computed: {
    ...mapState(['sum']),
    finalText() {
      return this.sum > GOALS[this.goalType] ? '🎉  ' + this.goalText + ' met!  🎉' : this.goalText
    }
  }
}
</script>

<style lang="scss" scoped>
#marker {
  position: absolute;
  border-top: 2px solid $dark;
  right: 0;
  width: 100%;
  color: #414042;
  font-size: 0.65rem;
  text-align: right;
  padding: 0;
  font-weight: 600;

  @media (min-width: 720px) {
    font-size: 1.25rem;
    padding: 0 5rem;
  }
}
span {
  background: white;
  border-radius: 8px;
  padding: 0 3px;
}
</style>
