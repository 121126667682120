<template lang="pug">
  #header-container
    h2 WPAi 2022 Sales Goals  
</template>

<script>
export default {
  name: 'page-header'
}
</script>

<style lang="scss" scoped>
#header-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 4vh;
  width: 100vw;
  background: #c8102e;
}
h2 {
  color: $accent;
}
</style>
