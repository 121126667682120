import { percentageOf } from './functions'

export const GOALS = {
  base: 14500000,
  stretch: 15587500,
  bhag: 16756562,
  jet: 17594390
}

export const GOAL_PERCENTS = {
  base: 100 - percentageOf(GOALS.base, GOALS.jet),
  stretch: 100 - percentageOf(GOALS.stretch, GOALS.jet),
  bhag: 100 - percentageOf(GOALS.bhag, GOALS.jet),
  jet: 0
}
