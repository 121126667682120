import Vue from 'vue'
import Vuex from 'vuex'
import millify from 'millify'
import { GOALS } from '../utils/constants'
import { percentageOf } from '../utils/functions'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sum: 0
  },
  getters: {
    prettySum: ({ sum }) => `$${millify(sum)}`,
    sumLoaded: ({ sum }) => sum > 0,
    goalPercent: ({ sum }) => percentageOf(sum, GOALS.jet)
  },
  mutations: {
    setSum(state, { sum }) {
      state.sum = sum
    }
  },
  actions: {
    setSum({ commit }, { sum }) {
      commit('setSum', { sum })
    }
  }
})
