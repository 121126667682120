<template lang="pug">
  #main-page-container
    page-header
    div(v-if="sumLoaded")
      sales-data
      plane-display
    loading-spinner(v-else)
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import PageHeader from '../molecules/PageHeader.vue'
import PlaneDisplay from '../organisms/PlaneDisplay.vue'
import SalesData from '../organisms/SalesData.vue'
import LoadingSpinner from '../atoms/LoadingSpinner.vue'

export default {
  name: 'main-page',
  components: {
    SalesData,
    PageHeader,
    PlaneDisplay,
    LoadingSpinner
  },
  computed: {
    ...mapGetters(['sumLoaded'])
  },
  methods: {
    async getAPIData() {
      // const { data } = await axios.get(
      //   'https://api.pipedrive.com/v1/deals?filter_id=198&limit=1000&api_token=b318fdb3264ce9997cca924f0c222986c306548e'
      // )
      // this.$store.dispatch('setSum', { sum: data.data.reduce((p, c) => p + c.value, 0) })
      const { data } = await axios.get(
        'https://api.pipedrive.com/v1/products/36?api_token=b318fdb3264ce9997cca924f0c222986c306548e'
      )
      const [priceData] = data.data.prices
      this.$store.dispatch('setSum', { sum: priceData.price })
      // this.$store.dispatch('setSum', { sum: 10700000 })
    }
  },
  mounted() {
    this.getAPIData()
  }
}
</script>

<style lang="scss" scoped>
#main-page-container {
  height: 100vh;
  text-align: center;
}
</style>
