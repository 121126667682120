<template lang="pug">
  #plane-container
    #box(:style="gradientObject")
      img.logo(alt="WPAi Logo" src='../../assets/wpailogo.png' :style="logoColor")
    goal-marker(:goalPosition="baseGoalPosition" :goalText="'Base goal ' + baseGoalAmount" goalType="base") 
    goal-marker(:goalPosition="stretchGoalPosition" :goalText="'Stretch goal ' + stretchGoalAmount" goalType="stretch") 
    goal-marker(:goalPosition="bhagGoalPosition" :goalText="'Puerto Rico goal ' + bhagGoalAmount" goalType="bhag") 
    goal-marker(:goalPosition="jetGoalPosition" :goalText="'Private Jet goal ' + jetGoalAmount" goalType="jet") 
    goal-marker(:goalPosition="goalPercentRemaining" :goalText="currentSales")
</template>

<script>
import { GOALS, GOAL_PERCENTS } from "../../utils/constants"
import { moneyFormat } from "../../utils/functions"
import GoalMarker from "../atoms/GoalMarker.vue"
export default {
  name: "plane-display",
  components: {
    GoalMarker
  },
  data() {
    return {
      baseGoalPosition: GOAL_PERCENTS.base,
      stretchGoalPosition: GOAL_PERCENTS.stretch,
      bhagGoalPosition: GOAL_PERCENTS.bhag,
      jetGoalPosition: 0,
      baseGoalAmount: moneyFormat(GOALS.base),
      stretchGoalAmount: moneyFormat(GOALS.stretch),
      bhagGoalAmount: moneyFormat(GOALS.bhag),
      jetGoalAmount: moneyFormat(GOALS.jet)
    }
  },
  computed: {
    goalPercentRemaining() {
      return 100 - this.$store.getters.goalPercent
    },
    gradientObject() {
      return {
        background: `linear-gradient(to bottom, #414042 ${this.goalPercentRemaining}%, #c8102e ${this.goalPercentRemaining}%)`
      }
    },
    currentSales() {
      return `Current Sales ${moneyFormat(this.$store.state.sum)}`
    },
    logoColor() {
      return this.goalPercentRemaining < 40 ? { filter: "grayscale(100%) invert(1)" } : null
    }
  }
}
</script>

<style lang="scss" scoped>
#plane-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#box {
  height: 320px;
  width: 320px;
  mask-image: url(../../assets/plane.svg);
  mask-repeat: no-repeat;
  mask-size: 333px;
  mask-position: center;

  @media (min-width: 720px) {
    height: 640px;
    width: 640px;
    mask-size: 670px;
  }

  @media (min-width: 1280px) {
    height: 900px;
    width: 900px;
    mask-size: 946px;
  }
}

.logo {
  position: relative;
  top: 30%;
  left: 10%;
  height: 10%;
  width: 30%;
}
</style>
